<template>
  <div class="bg-white w-full">
    <div>
      <div>
        <div class="font-bold mr-16 text-15">Information</div>
        <div class="flex">
          <table class="w-1/2 text-13 text-gray-600 mr-8">
            <tr>
              <td>Part Mass</td>
              <td class="text-black text-right">{{ partMass.value }}</td>
              <td>g</td>
            </tr>
            <tr v-if="activeChainIsAM">
              <td>Support Mass</td>
              <td class="text-black text-right">{{ supMass.value }}</td>
              <td>g</td>
            </tr>
            <tr v-if="activeChainIsAM">
              <td>Best Orientation</td>
              <td class="text-black text-right">
                {{
                  $formatZeroDecimalPlaces(activeProcessChain.rot_x_cost) +
                  '°, ' +
                  $formatZeroDecimalPlaces(activeProcessChain.rot_y_cost) +
                  '°, ' +
                  $formatZeroDecimalPlaces(activeProcessChain.rot_z_cost) +
                  '°'
                }}
              </td>
              <td />
            </tr>
          </table>
          <table class="w-1/2 text-13 text-gray-600">
            <tr v-if="activeChainIsAM">
              <td>Full Job Build Height</td>
              <td class="text-black text-right">{{ $formatZeroDecimalPlaces(activeProcessChain.bld_h) }}</td>
              <td>mm</td>
            </tr>
            <tr v-if="activeChainIsAM">
              <td>Cost Savings by Orientation</td>
              <td class="text-black text-right">{{ $formatZeroDecimalPlaces(costSave) }}</td>
              <td>%</td>
            </tr>
            <tr v-if="pricePerBoundingBoxVolume && calculationStyle == 'bounding_box' && activeChainIsAM">
              <td>Bounding Box Cost</td>
              <td class="text-black text-right">
                {{
                  $formatZeroDecimalPlaces(boundingBoxVolume.value) +
                  ' ' +
                  boundingBoxVolume.units +
                  ' @ ' +
                  $formatThreeDecimalPlaces(pricePerBoundingBoxVolume.value)
                }}
              </td>
              <td>{{ pricePerBoundingBoxVolume.units }}</td>
            </tr>
            <tr v-if="activeChainIsMilling">
              <td>Buy-To-Fly Ratio</td>
              <td class="text-black text-right">{{ buyToFlyRatio }}</td>
              <td />
            </tr>
          </table>
        </div>
      </div>

      <div v-if="activeChainIsAM" class="flex pt-16 items-center w-1/2">
        <div class="w-150 mr-16 text-13 text-gray-600">Parts per Job</div>
        <div class="mb-8 flex items-center">
          <div class="flex items-baseline justify-start w-150">
            <FeedbackValue
              :feedback-value="partsPerJob"
              field-style=""
              no-heading
              field-name=""
              field-unit=""
              field-type="normal"
              :is-hovered="isPartsPerJobHovered"
              @feedback-value-changed="setPartsPerJob($event)"
            />
          </div>
        </div>
        <div
          class="ml-4 cursor-pointer flex items-baseline text-gray-700"
          @mouseenter="isPartsPerJobHovered = true"
          @mouseleave="isPartsPerJobHovered = false"
        >
          <i class="fas fa-pen" />
        </div>
      </div>

      <div v-if="activeChainIsAM" class="flex pt-2 items-center w-1/2">
        <div class="w-150 mr-16 text-13 text-gray-600">Packing Density</div>
        <div class="mb-8 flex items-center">
          <div class="flex items-baseline justify-start w-150">
            <FeedbackValue
              :feedback-value="packingDensityPercentage"
              field-style=""
              no-heading
              field-name=""
              field-unit="%"
              field-type="normal"
              :is-hovered="isPackingDensityHovered"
              @feedback-value-changed="setPackingDensity($event)"
            />
          </div>
        </div>
        <div
          class="ml-4 cursor-pointer flex items-baseline text-gray-700"
          @mouseenter="isPackingDensityHovered = true"
          @mouseleave="isPackingDensityHovered = false"
        >
          <i class="fas fa-pen" />
        </div>
      </div>

      <div v-if="activeChainIsAM" class="flex pt-2 items-center w-1/2">
        <div class="w-150 mr-16 text-13 text-gray-600">Mean Build Height</div>
        <div class="mb-8 flex items-center">
          <div class="flex items-baseline justify-start w-150">
            <FeedbackValue
              :feedback-value="formattedMeanBuildHeight"
              field-style=""
              no-heading
              field-name=""
              field-unit="mm"
              field-type="normal"
              :is-hovered="isMeanBuildHeightHovered"
              @feedback-value-changed="setMeanBuildHeight($event)"
            />
          </div>
        </div>
        <div
          class="ml-4 cursor-pointer flex items-baseline text-gray-700"
          @mouseenter="isMeanBuildHeightHovered = true"
          @mouseleave="isMeanBuildHeightHovered = false"
        >
          <i class="fas fa-pen" />
        </div>
      </div>

      <FeasibilityChecksList
        :active-process-chain="activeProcessChain"
        show-configure-button
        @show-weights-modal="showWeightsModal"
      />
    </div>
    <FeasibilityWeightsModal
      :process-chain-id="activeProcessChain.process_chain_id"
      :show="showModal"
      @close="close()"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import FeasibilityChecksList from '@/components/PartWorkflow/Feasibility/FeasibilityChecksList.vue';
import FeasibilityWeightsModal from '@/components/PartWorkflow/Feasibility/FeasibilityWeightsModal.vue';
import FeedbackValue from '@/components/Reusable/FeedbackValues/FeedbackValue.vue';

export default {
  name: 'FeasibilityCard',

  components: {
    FeasibilityWeightsModal,
    FeasibilityChecksList,
    FeedbackValue,
  },

  data() {
    return {
      showModal: false,
      partsPerJob: 1,
      meanBuildHeight: null,
      isPartsPerJobHovered: false,
      isPackingDensityHovered: false,
      isMeanBuildHeightHovered: false,
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    ...mapState('application', ['axiosInstance']),

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    activeChainIsAM() {
      return this.activeProcessChain.tech == 'additive_manufacturing';
    },

    activeChainIsMilling() {
      return this.activeProcessChain.tech == 'milling';
    },

    activeChainIsCasting() {
      return this.activeProcessChain.tech == 'casting';
    },

    partMass() {
      let mass = this.activeProcessChain.part_mass;
      return this.roundSmallNumber(mass, 2);
    },

    supMass() {
      let mass = this.activeProcessChain.sup_mass;
      return this.roundSmallNumber(mass, 2);
    },

    costSave() {
      if (this.activeProcessChain.cost_ini_pp) {
        let percentage =
          Math.round(
            ((1 - this.activeProcessChain.cost.cost_pp / this.activeProcessChain.cost_ini_pp) * 100 + Number.EPSILON) *
              100
          ) / 100;
        return percentage > 0 ? percentage : 0;
      } else {
        return null;
      }
    },

    boundingBoxVolume() {
      return {
        value: (this.part.bb_x * this.part.bb_y * this.part.bb_z) / 1000,
        units: 'cm³',
      };
    },

    pricePerBoundingBoxVolume() {
      return {
        value: this.activeProcessChain?.price_per_bounding_box_volume,
        units: '€/cm³',
      };
    },

    buyToFlyRatio() {
      return this.activeProcessChain.info['Buy-To-Fly Ratio'] || null;
    },

    calculationStyle() {
      return this.activeProcessChain?.calc_style;
    },

    packingDensityPercentage() {
      return (this.activeProcessChain.packing_density * 100 || 0).toFixed(1);
    },

    formattedMeanBuildHeight() {
      return this.activeProcessChain.bld_h_mean.toFixed(1);
    },
  },

  mounted() {
    this.partsPerJob = this.activeProcessChain.parts_pj;
    this.meanBuildHeight = this.activeProcessChain.bld_h_mean;
  },

  methods: {
    ...mapMutations(['updatePartsPj', 'updatePackingDensity', 'updateMeanBuildHeight']),
    roundSmallNumber(number, digits) {
      if (number != undefined && number < 1 && number != 0) {
        return { value: number.toFixed(digits) };
      } else {
        return { value: this.$formatOneDecimalPlace(number) };
      }
    },

    setPartsPerJob(event) {
      this.updatePartsPj({ uid: this.activeInvestigationDetails.uid, value: event });

      let formData = {
        parts_pj: event,
      };
      this.axiosInstance.put(`/api/v1/process-chain-feedback/${this.activeProcessChain.process_chain_id}/`, formData);
    },

    showWeightsModal() {
      this.showModal = true;
    },

    close() {
      this.showModal = false;
    },

    setPackingDensity(event) {
      const density = event / 100; // Convert percentage back to decimal
      this.updatePackingDensity({ uid: this.activeInvestigationDetails.uid, value: density });

      let formData = {
        packing_density: density,
      };
      this.axiosInstance.put(`/api/v1/process-chain-feedback/${this.activeProcessChain.process_chain_id}/`, formData);
    },

    setMeanBuildHeight(event) {
      this.updateMeanBuildHeight({ uid: this.activeInvestigationDetails.uid, value: event });

      let formData = {
        bld_h_mean: event,
      };
      this.axiosInstance.put(`/api/v1/process-chain-feedback/${this.activeProcessChain.process_chain_id}/`, formData);
    },
  },
};
</script>
