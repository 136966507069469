<template>
  <div class="flex flex-wrap gap-16 items-center ml-10 pt-4">
    <div v-for="(cat, id) in sortedCategories" :key="id">
      <SparkSelect
        v-if="cat.category_type === 'combo_category'"
        v-model="cat.uid"
        :options="cat.options"
        :label="cat.category + (cat.required ? '*' : '')"
        name="combo_category"
        :title="lockedForUser ? lockedTitle : ''"
        :disabled="lockedForUser"
        :class="!cat.uid && cat.required && highlightCategories ? 'highlight-red-border' : ''"
        @update:model-value="saveComboCategory(id, cat)"
      />

      <SparkInput
        v-if="cat.category_type === 'text_category'"
        v-model="cat.value"
        name="text_category"
        :label="cat.category + (cat.required ? '*' : '')"
        :title="lockedForUser ? lockedTitle : ''"
        :disabled="lockedForUser"
        :class="!cat.value && cat.required && highlightCategories ? 'highlight-red-border' : ''"
        @change="saveTextCategory(id, cat)"
      />
      <div v-if="validateThisUrl(cat.value)" title="Go to" class="external-link-icon" @click="goToUrl(cat.value)">
        <i class="fas fa-external-link-alt" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import validateUrl from '@/composables/validateUrl.js';

export default {
  name: 'CategoryInput',

  components: { SparkInput, SparkSelect },

  props: {
    requiredCategories: { type: Boolean },
  },

  emits: ['category'],

  data() {
    return {
      comboCategories: {},
      textCategories: {},
    };
  },

  computed: {
    ...mapState(['part', 'user', 'highlightCategories']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    allComboCategories() {
      return this.part.categories.combo_categories;
    },

    sortedCategories() {
      const allCategories = { ...this.part.categories.combo_categories, ...this.part.categories.text_categories };
      const entries = Object.entries(allCategories);
      entries.sort(([, a], [, b]) => {
        if (a['order_index'] < b['order_index']) {
          return -1;
        }
        if (a['order_index'] > b['order_index']) {
          return 1;
        }
        return 0;
      });

      const sortedObj = Object.fromEntries(entries);
      return sortedObj;
    },

    highlightCategories() {
      return this.part.highlightCategories;
    },
  },

  watch: {
    allComboCategories: {
      handler() {
        if (this.allComboCategories) {
          // get comboCAtegories if not already initialized in part
          this.setPartCategories();
        }
        this.getCategories();
      },

      immediate: true,
    },

    sortedCategories: {
      handler() {
        this.addOptionsToEntries(this.sortedCategories);
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['setPartCategories']),

    validateThisUrl(url) {
      return validateUrl(url).validateBool;
    },

    goToUrl(url) {
      let prefix = 'https://';
      if (!url.includes(prefix) && !url.includes('http://')) {
        url = prefix + url;
      }
      window.open(url, '_blank');
    },

    getCategories() {
      for (let [key, value] of Object.entries(this.allComboCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.comboCategories[key] = value;
      }
      let allTextCategories = this.part.categories.text_categories;
      for (let [key, value] of Object.entries(allTextCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.textCategories[key] = value;
      }
    },

    saveTextCategory(id, textcat) {
      let cat = {};
      cat[id] = {
        category: textcat.category,
        value: textcat.value,
        required: textcat.required,
      };
      this.$emit('category', { text_categories: cat });
    },

    saveComboCategory(id, combocat) {
      let cat = {};
      if (combocat.uid != '') {
        cat[id] = {
          uid: combocat.uid,
          value: combocat.items[combocat.uid],
        };
      } else {
        cat[id] = {
          uid: '',
          value: '',
        };
      }
      this.$emit('category', { combo_categories: cat });
    },

    addOptionsToEntries(data) {
      const entries = data;
      Object.keys(entries).forEach(key => {
        const entry = entries[key];

        if (Object.prototype.hasOwnProperty.call(entry, 'items')) {
          entry.options = Object.keys(entry.items).map(itemKey => ({
            label: entry.items[itemKey],
            value: itemKey,
          }));
        }

        if (entry.options) {
          entry.options.unshift({
            label: '',
            value: '',
          });
        }
      });

      return entries;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  transition: all 0.3s ease-out;
}

.flex {
  display: flex;
  align-items: center;
}
.external-link-icon {
  cursor: pointer;
  color: var(--spark-lightgrey);
  font-size: var(--12px);
  &:hover {
    color: black;
  }
  padding: 5px;
}
.highlight-red-border {
  border-color: var(--spark-delete);
}

.spark-new-input-group {
  width: 200px;
}

.crop {
  text-overflow: ellipsis;
}

.spark-new-select-label,
.spark-new-input-label {
  max-width: 150px;
}

.border {
  border: 1px solid var(--light-color);
}
</style>
