<template>
  <SparkModal :model-value="show" dismissable @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Changing of Part Status'" />
    </template>

    <template #default>
      <div class="bg-primary-50 text-primary-800 p-8 rounded-4 my-8 border border-primary-300">{{ message }}</div>
      <div class="flex flex-col w-full gap-8 mb-16">
        <div class="flex">
          <div class="text-gray-600 w-1/3">Primary Process Chain</div>
          <div class="w-2/3">{{ primaryProcessChainName }}</div>
        </div>
        <div class="flex justify-between items-center">
          <div class="text-gray-600 w-1/3">Benchmark Process Chain</div>
          <SparkSelect
            v-model="selectedBenchmarkProcessChainUid"
            class="ml-4 pt-8 w-2/3"
            custom="text-13"
            :options="benchmarkProcessChainOptions"
            name="benachmarkProcessChain"
            label=""
            @update:model-value="updateBenchmarkProcessChain"
          />
        </div>
        <div class="flex">
          <div class="text-gray-600 w-1/3">Lot Size</div>
          <div class="w-2/3">{{ lotSize }}</div>
        </div>
      </div>
      <div class="flex flex-col w-full gap-8 mb-16">
        <div class="flex">
          <div class="text-gray-600 w-1/3">
            <div>Cost Saved</div>

            <div v-if="!showForm && primaryAndBenchmarkExisting">
              <div class="" @click="showadditionalForm()">
                <p class="text-primary-600 cursor-pointer hover:text-primary-500">Add additional Cost Savings</p>
              </div>
            </div>
          </div>
          <div class="w-2/3">
            <div class="flex justify-between pb-8">
              <div class="text-gray-600">Manufacturing Cost Saved</div>
              <div class="flex">
                <div>{{ $formatZeroDecimalPlaces(costSaved) }} € <span class="text-gray-600">per Lot</span></div>
                <i class="pl-4 fas fa-trash text-white" />
              </div>
            </div>

            <div v-for="cost in additionalSaving" :key="cost.uid" class="flex justify-between pb-8">
              <template v-if="cost != undefined">
                <div class="ml-16">
                  <div class="text-gray-600">{{ savingsTypes[cost.savings_type?.uid]?.value }}</div>
                  <div v-if="cost.description != ''" class="text-11">{{ cost.description }}</div>
                </div>
                <div class="flex items-center">
                  <div>{{ cost.custom_cost_saved_pl }} € <span class="text-gray-600">per Lot</span></div>
                  <i class="fas fa-trash hover:text-primary-500 cursor-pointer pl-4" @click="deleteItem(cost.uid)" />
                </div>
              </template>
            </div>
          </div>
        </div>

        <div v-if="showForm" class="border border-gray-50 rounded-4 p-8">
          <SparkSelect
            v-model="formData.savings_type"
            required
            name="savings_type"
            class="mb-16"
            :options="savingsTypeOptions"
            label="Type"
          />
          <SparkInput v-model="formData.value" required name="value" label="Value in €" />
          <SparkSelect
            v-model="formData.level"
            required
            name="level"
            :options="levelOptions"
            label="Level"
            class="mb-16"
          />
          <SparkInput v-model="formData.description" required name="description" label="Description" />
          <div class="flex justify-end gap-16">
            <i class="fas fa-check icon-button" title="Add" @click="add()" />
            <i class="fas fa-times icon-button" title="Discard" @click="closeForm()" />
          </div>
        </div>

        <div class="flex justify-between pb-8">
          <div class="text-gray-600">Sum Cost Saved</div>
          <div class="flex">
            <div>{{ sumCostSavings }}<span class="text-gray-600"> per Lot</span></div>
            <i class="pl-4 fas fa-trash text-white" />
          </div>
        </div>

        <div class="flex justify-between pb-8">
          <div class="text-gray-600">Lead Time Saved</div>
          <div class="flex">
            <div>{{ leadtimeSaved }}</div>
            <i class="pl-4 fas fa-trash text-white" />
          </div>
        </div>
        <div class="flex justify-between pb-8">
          <div class="text-gray-600">Co2 Saved</div>
          <div class="flex">
            <div>{{ co2Saved }}</div>
            <i class="pl-4 fas fa-trash text-white" />
          </div>
        </div>
      </div>
      <p v-if="errorMessage" class="warning">{{ errorMessage }}</p>
      <div class="flex justify-end gap-16">
        <SparkButton variant="outlined" @click="close">
          <div v-text="'Cancel'" />
        </SparkButton>
        <SparkButton custom="!min-w-[80px]" variant="secondary" @click="save">
          <div v-text="'Save'" />
        </SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
export default {
  name: 'StatusModal',

  components: {
    SparkModal,
    SparkButton,
    SparkSelect,
    SparkInput,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    additionalData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close', 'save'],

  data() {
    return {
      showForm: false,
      levelOptions: [
        { label: 'Part', value: 'cost_pp' },
        { label: 'Lot', value: 'cost_pl' },
      ],

      formData: {
        savings_type: '',
        value: '',
        level: '',
        description: '',
      },

      errorMessage: '',
      selectedBenchmarkProcessChainUid: '',
    };
  },

  computed: {
    ...mapState(['user', 'part', 'organization']),
    ...mapState('application', ['axiosInstance']),

    ...mapGetters(['processChainsAnalysisFinished']),

    benchmarkProcessChainOptions() {
      let options = [];
      Object.keys(this.part.process_chains).forEach(key => {
        if (key != this.primaryProcessChainUid) {
          options.push({ value: key, label: this.part.process_chains[key].name });
        }
      });
      return options;
    },

    oldStat() {
      return this.part.part_stat;
    },

    additionalSaving() {
      return this.part.additional_savings;
    },

    leadtimeSaved() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let primaryLeadtime = this.part.process_chains[this.primaryProcessChainUid]?.leadtime;
        let benchmarkLeadtime = this.part.process_chains[this.benchmarkProcessChainUid]?.leadtime;
        if (benchmarkLeadtime - primaryLeadtime < 0) {
          return '-';
        } else {
          return this.$formatZeroDecimalPlaces(benchmarkLeadtime - primaryLeadtime) + ' d';
        }
      } else {
        return '-';
      }
    },

    costSaved() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let primaryCost = this.part.process_chains[this.primaryProcessChainUid]?.cost?.cost_pl;
        let benchmarkCost = this.part.process_chains[this.benchmarkProcessChainUid]?.cost?.cost_pl;
        return benchmarkCost - primaryCost;
      } else {
        return 0;
      }
    },

    co2Saved() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let primaryco2 = this.part.process_chains[this.primaryProcessChainUid]?.co2?.co2_pl;
        let benchmarkco2 = this.part.process_chains[this.benchmarkProcessChainUid]?.co2?.co2_pl;
        if (benchmarkco2 - primaryco2 < 0) {
          return '-';
        } else {
          return this.$formatZeroDecimalPlaces(benchmarkco2 - primaryco2) + ' kg CO2-eq';
        }
      } else {
        return '-';
      }
    },

    message() {
      if (this.primaryAndBenchmarkExisting && this.processChainsAnalysisFinished) {
        let prefix = 'Please review the following savings, before changing the status';
        if (this.oldStat == 'unset') {
          return `${prefix} to '${this.additionalData.newStatText}'.`;
        } else {
          return `${prefix} from '${this.additionalData.oldStatText}' to '${this.additionalData.newStatText}'.`;
        }
      } else if (!this.primaryAndBenchmarkExisting && !this.processChainsAnalysisFinished) {
        return 'Main Process AND Benchmark Process must be selected.';
      } else if (!this.processChainsAnalysisFinished) {
        return 'Processes must be analyzed.';
      } else {
        return '';
      }
    },

    lotSize() {
      if (this.primaryAndBenchmarkExisting) {
        return this.part.process_chains[this.primaryProcessChainUid]?.lot_size;
      } else {
        return '-';
      }
    },

    primaryProcessChainName() {
      return this.part.process_chains[this.primaryProcessChainUid]?.name;
    },

    primaryProcessChainUid() {
      return this.part.primary_process_chain_id;
    },

    benchmarkProcessChainUid() {
      return this.part.benchmark_process_chain_id;
    },

    primaryAndBenchmarkExisting() {
      return (
        this.primaryProcessChainUid != null ||
        (this.primaryProcessChainUid != '' && this.benchmarkProcessChainUid != null) ||
        this.benchmarkProcessChainUid != ''
      );
    },

    savingsTypeOptions() {
      return Object.entries(this.organization.savings_type_choices).map(([key, value]) => ({
        label: value.value,
        value: key,
      }));
    },

    savingsTypes() {
      return this.organization.savings_type_choices;
    },

    sumCostSavings() {
      let primaryCostSaved = this.costSaved;
      let sumCostSaved = primaryCostSaved;

      if (Array.isArray(this.additionalSaving)) {
        this.additionalSaving.forEach(saving => {
          let cost = parseFloat(saving.custom_cost_saved_pl);
          if (!isNaN(cost)) {
            sumCostSaved += cost;
          }
        });
      }

      if (primaryCostSaved < 0 && sumCostSaved < 0) {
        return '0 €';
      }

      return sumCostSaved === 0 ? '-' : this.$formatZeroDecimalPlaces(sumCostSaved) + ' €';
    },
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.selectedBenchmarkProcessChainUid = this.benchmarkProcessChainUid;
      }
    },
  },

  methods: {
    ...mapMutations(['addAdditionalSaving', 'removeAdditionalSaving', 'updatePart', 'changeProcessChainUids']),

    close() {
      this.$emit('close');
    },

    save() {
      this.$emit('save');
    },

    showadditionalForm() {
      this.showForm = true;
      this.errorMessage = '';
    },

    async deleteItem(uid) {
      try {
        await this.axiosInstance.delete(`api/v1/additional-saving/${uid}/`);
        this.removeAdditionalSaving(uid);
        this.errorMessage = '';
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error_message) {
          this.errorMessage = `⚠️ ${error.response.data.error_message}`;
        } else {
          this.errorMessage = '⚠️ Error deleting the item.';
        }
        console.log(error);
      }
    },

    updateBenchmarkProcessChain() {
      let formData = {};
      formData['benchmark_process_chain_id'] = this.selectedBenchmarkProcessChainUid;
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).then(response => {
        this.changeProcessChainUids(formData);
        this.updatePart(response.data.part);
      });
    },

    async add() {
      if (parseFloat(this.formData.value) < 0) {
        this.errorMessage = '⚠️ Additional cost savings must be positive.';
        return;
      }

      this.formData['part_id'] = this.part.part_id;
      this.formData[this.formData.level] = this.formData.value;
      this.showForm = false;
      let savingsTypeUid = this.formData.savings_type;
      this.formData['savings_type'] = { uid: savingsTypeUid, value: this.savingsTypes[savingsTypeUid].value };
      this.formData['savings_type_uid'] = savingsTypeUid;

      try {
        await this.axiosInstance.post('api/v1/additional-saving/', this.formData);
        this.addAdditionalSaving(this.formData);
        this.formData = {};
        this.errorMessage = '';
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error_message) {
          this.errorMessage = `⚠️ ${error.response.data.error_message}`;
        } else {
          this.errorMessage = '⚠️ Error adding the additional cost saving.';
        }
        console.log(error);
      }
    },

    closeForm() {
      this.showForm = false;
      this.errorMessage = '';
    },
  },
};
</script>
