<template>
  <div v-if="chain != undefined" class="print:bg-white">
    <div v-if="!analysisFinished" class="pl-16 p-4 text-13 text-red-500 bg-red-50 rounded-8">
      Warning: Part is not analyzed!
    </div>
    <div class="report flex flex-col space-y-8 p-8">
      <div class="flex flex-col p-20 bg-white border border-gray-50 rounded-8">
        <div class="font-bold text-15 pb-12">{{ part.name }}</div>
        <div class="flex text-13 pb-12">
          <div class="w-1/3 text-gray-600">Main Process Chain</div>
          <div class="w-2/3 flex items-center gap-8">
            <div>{{ chain.name }}</div>
            -
            <div>{{ chain.prc }}</div>
            <div class="flex w-[3px] h-[3px] bg-gray-400 rounded-full" />
            <div>{{ chain.material_name }}</div>
            <div class="flex w-[3px] h-[3px] bg-gray-400 rounded-full" />
            <div>{{ chain.machine_name }}</div>
          </div>
        </div>
        <div class="flex text-13 items-center">
          <div class="w-1/3 mt-12 text-gray-600">Benchmark Process Chain</div>
          <div v-if="!benchmarkProcessChainSelect" class="flex items-center w-2/3 mt-12">
            <div v-if="part.benchmark_process_chain_id != undefined">
              {{ part.process_chains[part.benchmark_process_chain_id]?.name }}
            </div>
            <div v-else>No Benchmark selected</div>
            <i
              v-if="part.benchmark_process_chain_id != undefined"
              class="fas fa-edit ml-16 text-gray-800 cursor-pointer hover:text-primary-600"
              @click="benchmarkProcessChainSelect = true"
            />
          </div>
          <div v-else class="flex items-center w-2/3">
            <SparkSelect
              v-model="selectedBenchmarkProcessChainUid"
              custom="text-13 w-full"
              :options="benchmarkProcessChainOptions"
              name="benachmarkProcessChain"
              label=""
              @update:model-value="updateBenchmarkProcessChain"
            />
            <i
              class="fas fa-times ml-16 text-gray-800 cursor-pointer hover:text-primary-600"
              @click="benchmarkProcessChainSelect = false"
            />
          </div>
        </div>
      </div>
      <div class="flex gap-20">
        <div class="flex flex-col w-2/3 space-y-20">
          <div class="flex flex-col p-8 bg-white border border-gray-50 rounded-8">
            <div class="text-gray-900 text-15 flex p-20">
              <div>Summary</div>
              <div><FeasibilityValueAndPieChart class="h-24 ml-16" :percentage="overallFeasibility" /></div>
            </div>

            <div class="text-13 flex pl-20">
              <div v-if="chain.process_chain_id == part.primary_process_chain_id" class="w-1/2 space-y-12">
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Cost Saved</div>
                  <div v-if="singleProcessChain">No Benchmark selected</div>
                  <div v-if="!singleProcessChain" class="v-align">
                    {{ costSaved }}<span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Lead Time Saved</div>
                  <div v-if="singleProcessChain">No Benchmark selected</div>
                  <div v-if="!singleProcessChain" class="v-align">
                    {{ leadtimeSaved }} <span v-if="!singleProcessChain" class="text-gray-400 pl-4">days</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Carbon Emission Saved</div>
                  <div v-if="singleProcessChain">No Benchmark selected</div>
                  <div v-if="!singleProcessChain" class="v-align">
                    {{ co2Saved }} <span v-if="!singleProcessChain" class="text-gray-400 pl-4">kg CO₂-eq</span>
                  </div>
                </div>
              </div>

              <div class="flex w-1/2 flex-col space-y-12">
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Manufacturing Cost per Part</div>
                  <div>{{ $formatTwoDecimalPlaces(amCostPerPart) }} <span class="text-gray-400 pl-4">€</span></div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Quote Price per Part</div>
                  <div>{{ $formatTwoDecimalPlaces(marketPricePerPart) }} <span class="text-gray-400 pl-4">€</span></div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Carbon Emission per Lot</div>
                  <div>
                    {{ $formatTwoDecimalPlaces(chain.co2.co2_pl) }} <span class="text-gray-400 pl-4">kg CO₂-eq</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="actualChainIsTechAM" class="flex flex-col p-8 bg-white border border-gray-50 rounded-8 text-13">
            <div class="text-gray-900 text-15 flex p-20">
              <div>Cost</div>
              <div><FeasibilityValueAndPieChart class="h-24 ml-16" :percentage="economicalFeasibility" /></div>
            </div>
            <div class="flex pb-20">
              <div class="flex w-1/2 flex-col space-y-12 pl-20">
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Lot Size</div>
                  <div class="v-align">
                    {{ chain.lot_size }}
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Cost per Lot</div>
                  <div class="v-align">
                    {{ $formatTwoDecimalPlaces(chain.cost.cost_pl) }} <span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Parts per Job</div>
                  <div class="v-align">
                    {{ chain.parts_pj }}
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3">Cost per Job</div>
                  <div class="v-align">
                    {{ $formatTwoDecimalPlaces(chain.cost.cost_pj) }} <span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>
              </div>
              <div class="flex w-1/2 flex-col space-y-12">
                <div class="flex">
                  <div class="text-gray-600 w-1/3 flex items-center">
                    <div :style="{ 'background-color': paletteArray[0] }" class="w-16 h-16 rounded-4 mr-8" />
                    <div>Pre-Process</div>
                  </div>
                  <div class="v-align">
                    {{ $formatTwoDecimalPlaces(amCostBreakdown.pre_process) }} <span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3 flex items-center">
                    <div :style="{ 'background-color': paletteArray[1] }" class="w-16 h-16 rounded-4 mr-8" />
                    <div>In-Process</div>
                  </div>
                  <div class="v-align">
                    {{ $formatTwoDecimalPlaces(amCostBreakdown.in_process) }} <span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>

                <div class="flex">
                  <div class="text-gray-600 w-1/3 flex items-center">
                    <div :style="{ 'background-color': paletteArray[2] }" class="w-16 h-16 rounded-4 mr-8" />
                    <div>Post-Process</div>
                  </div>
                  <div class="v-align">
                    {{ $formatTwoDecimalPlaces(amCostBreakdown.post_process) }}
                    <span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>
                <div class="flex">
                  <div class="text-gray-600 w-1/3 flex items-center">
                    <div :style="{ 'background-color': paletteArray[3] }" class="w-16 h-16 rounded-4 mr-8" />
                    <div>Material</div>
                  </div>
                  <div class="v-align">
                    {{ $formatTwoDecimalPlaces(amCostBreakdown.material) }} <span class="text-gray-400 pl-4">€</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="feastable pagebreak-before bg-white border border-gray-50 rounded-8 pl-20 pb-20">
            <FeasibilityChecksList :active-process-chain="chain" />
          </div>
        </div>

        <div class="flex flex-col w-1/3">
          <div v-if="actualChainIsTechAM" class="flex bg-white border border-gray-50 rounded-8 mb-20">
            <div class="flex flex-col w-full">
              <div class="text-gray-900 text-15 p-20">
                <div>AM Manufacturing Cost per Part</div>
              </div>

              <CostChart :chain="chain" :label="label" short-bool @mounted="childComponentsMounted += 1" />
            </div>
          </div>

          <div class="flex flex-col p-20 bg-white border border-gray-50 rounded-8 text-13">
            <div>
              <div class="text-gray-900 text-15 flex pb-12">
                <div>Technology Overview</div>
              </div>
              <div>
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Part Mass</div>
                  <div>{{ $formatZeroDecimalPlaces(chain.part_mass) }}<span class="text-gray-400 pl-4">g</span></div>
                </div>
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Part Volume</div>
                  <div>{{ $formatOneDecimalPlace(part.part_vol) }}<span class="text-gray-400 pl-4">cm³</span></div>
                </div>
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Bounding Box</div>
                  <div>
                    {{ $formatOneDecimalPlace(part.bb_x) }} x {{ $formatOneDecimalPlace(part.bb_y) }} x
                    {{ $formatOneDecimalPlace(part.bb_z) }}<span class="text-gray-400 pl-4">mm </span>
                  </div>
                </div>
              </div>
              <div v-if="actualChainIsTechAM">
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Part SupportMass</div>
                  <div>
                    {{ $formatZeroDecimalPlaces(chain.sup_mass) }} ({{
                      $formatZeroDecimalPlaces(supportPercent)
                    }}
                    %)<span class="text-gray-400 pl-4">g</span>
                  </div>
                </div>
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Minimum Bounding Box</div>
                  <div>
                    {{ $formatOneDecimalPlace(part.min_bb_x) }} x {{ $formatOneDecimalPlace(part.min_bb_y) }} x
                    {{ $formatOneDecimalPlace(part.min_bb_z) }}<span class="text-gray-400 pl-4">mm</span>
                  </div>
                </div>
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Number of Layers</div>
                  <div>
                    {{ chain.n_lyr }}
                  </div>
                </div>
                <div class="flex justify-between space-y-12">
                  <div class="text-gray-600">Full Job Build Height</div>
                  <div>{{ $formatOneDecimalPlace(chain.bld_h) }}<span class="text-gray-400 pl-4">mm</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-64">
        <div
          class="processchain pagebreak-before pagebreak-after bg-white border border-gray-50 rounded-8 pl-20 pb-20 mb-64"
        >
          <div class="text-gray-900 text-15 flex p-20">Process Chain</div>
          <ProcessChain :chain="chain" :editable="false" :display-title="false" pdf-view class="text-13" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import CostChart from '@/components/PartWorkflow/Cost/CostChart.vue';
import FeasibilityChecksList from '@/components/PartWorkflow/Feasibility/FeasibilityChecksList.vue';
import FeasibilityValueAndPieChart from '@/components/PartWorkflow/Feasibility/FeasibilityValueAndPieChart.vue';
import ProcessChain from '@/components/PartWorkflow/ProcessChain/ProcessChain.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'DownloadPdf',

  components: {
    CostChart,
    FeasibilityChecksList,
    ProcessChain,
    FeasibilityValueAndPieChart,
    SparkSelect,
  },

  data() {
    return {
      benchmarkProcessChainSelect: false,
      childComponentsMounted: 0,
      selectedBenchmarkProcessChainUid: '',
      label: {
        labor: 'Labor',
        machine: 'Machine',
        other: 'Other',
        build_material: 'Build Material',
        support_material: 'Support Material',
        waste_material: 'Waste Material',
      },
    };
  },

  computed: {
    ...mapState(['part', 'user']),
    ...mapState('application', ['axiosInstance']),
    benchmarkProcessChainUid() {
      return this.part.benchmark_process_chain_id;
    },

    costSaved() {
      if (this.part.cost_saved * this.chain.lot_size < 0) return '-';
      else {
        return this.$formatTwoDecimalPlaces(this.part.cost_saved * this.chain.lot_size);
      }
    },

    leadtimeSaved() {
      if (this.part.leadtime_saved < 0) return '-';
      else {
        return this.part.leadtime_saved;
      }
    },

    co2Saved() {
      let primaryco2 = this.chain?.co2.co2_pl;
      if (this.benchmarkProcessChainUid) {
        let benchmarkco2 = this.processChains[this.benchmarkProcessChainUid]?.co2.co2_pl;

        if (benchmarkco2 - primaryco2 < 0) return '-';
        else return this.$formatTwoDecimalPlaces(benchmarkco2 - primaryco2);
      } else {
        return '-';
      }
    },

    singleProcessChain() {
      return Object.keys(this.processChains).length == 1;
    },

    pdfRequested() {
      return this.part.pdf_requested;
    },

    chain() {
      let process_chain_id = this.$route.params.process_chain_id;
      if (this.processChains != undefined) {
        return this.processChains[process_chain_id];
      } else {
        return {};
      }
    },

    benchmarkProcessChainOptions() {
      let options = [];
      Object.keys(this.processChains).forEach(key => {
        if (key != this.chain.process_chain_id) {
          options.push({ value: key, label: this.processChains[key].name });
        }
      });
      return options;
    },

    processChains() {
      return this.part.process_chains;
    },

    actualChainIsTechAM() {
      return this.chain?.tech == 'additive_manufacturing';
    },

    paletteArray() {
      return getComputedStyle(document.body).getPropertyValue('--chart-palette').split(',');
    },

    amCostBreakdown() {
      const obj = this.chain.cost_breakdown || {};
      const categories = ['pre_process', 'in_process', 'post_process', 'material'];

      return categories.reduce((result, category) => {
        const categoryData = obj[category] || {};
        result[category] = Object.values(categoryData).reduce((sum, value) => {
          return sum + (Number.isFinite(value) ? value : 0);
        }, 0);
        result[category] = Number(result[category].toFixed(2));
        return result;
      }, {});
    },

    amCostPerPart() {
      return this.chain?.cost.cost_pp;
    },

    supportPercent() {
      return (this.chain.sup_mass / this.chain.part_mass) * 100;
    },

    marketPricePerPart() {
      let marketPrice = this.chain.market_price.market_price_pp;
      if (marketPrice.user == null) {
        return marketPrice.calculated;
      } else {
        return marketPrice.user;
      }
    },

    economicalFeasibility() {
      return this.chain?.float_suitabilities?.economic;
    },

    overallFeasibility() {
      return this.chain?.float_suitabilities?.overall;
    },

    costSave() {
      if (this.chain.cost_ini_pp) {
        return Math.round(((1 - this.chain.cost.cost_pp / this.chain.cost_ini_pp) * 100 + Number.EPSILON) * 100) / 100;
      } else {
        return null;
      }
    },

    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },
  },

  watch: {
    pdfRequested(newVal) {
      if (newVal == true) {
        setTimeout(this.print, 1000);
        this.requestPdf(false);
      }
    },
  },

  mounted() {
    this.selectedBenchmarkProcessChainUid = this.benchmarkProcessChainUid;
  },

  methods: {
    ...mapMutations('canvas', ['updateReloadCanvas']),
    ...mapMutations(['updatePart', 'changeProcessChainUids']),
    ...mapMutations({
      requestPdf: 'pdfRequested',
    }),

    updateBenchmarkProcessChain() {
      let formData = {};
      formData['benchmark_process_chain_id'] = this.selectedBenchmarkProcessChainUid;
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, formData).then(response => {
        this.changeProcessChainUids(formData);
        this.updatePart(response.data.part);
      });
    },

    print() {
      this.setPrintDisplay('none'); // Hide elements
      window.print();
      this.setPrintDisplay(''); // Show elements
    },

    setPrintDisplay(value) {
      // Popup
      const popupElements = document.getElementsByClassName('confirm');
      for (let i = 0; i < popupElements.length; i++) {
        popupElements[i].style.display = value;
      }

      // SubNav
      const subNavElements = document.getElementsByClassName('navi');
      for (let i = 0; i < subNavElements.length; i++) {
        subNavElements[i].style.display = value;
      }

      // Footer
      const footerElements = document.getElementsByClassName('part-footer');
      for (let i = 0; i < footerElements.length; i++) {
        footerElements[i].style.display = value;
      }

      // Navbar
      const navElement = document.getElementById('nav');
      if (navElement) {
        navElement.style.display = value;
      }

      // Buttons
      const buttonsElement = document.getElementById('buttons');
      if (buttonsElement) {
        buttonsElement.style.display = value;
      }

      const chatIcon = document.getElementById('chatling-open-chat-icon');
      if (chatIcon) {
        chatIcon.style.display = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// h1 {
//   font-size: var(--18px);
// }

// h2 {
//   padding-top: 5px;
//   font-size: var(--15px);
// }

// .report {
//   print-color-adjust: exact !important;
// }

// @media only screen and (min-width: 800px) {
//   .report {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
//     max-width: 100%;
//     min-width: 0;
//     grid-auto-rows: auto auto auto auto auto;
//     grid-template-areas: 'title header' 'summary summary' 'summary-1 summary-2' ' costtable feastable ' ' costchart  overview' 'processchain processchain';
//     print-color-adjust: exact !important;
//   }
// }

// .overview {
//   grid-area: overview;
//   min-width: 0;
//   max-width: 100%;
//   padding: 20px 20px 20px 0px;
// }

// .processchain {
//   grid-area: processchain;
//   margin: 10px;
//   padding: 20px 20px 20px 0px;
//   min-width: 0;
// }

// .costtable {
//   grid-area: costtable;
//   min-width: 0;
//   padding: 20px 20px 20px 0px;
// }

// .costchart {
//   grid-area: costchart;
//   min-width: 0;
//   padding: 20px 20px 20px 0px;
//   max-width: 79%;
// }

// .feastable {
//   grid-area: feastable;
//   min-width: 0;
//   padding: 20px 20px 20px 0px;
// }
// .summary {
//   cursor: default;
//   grid-area: summary;
//   min-width: 0;
//   padding: 10px;
// }

// .superscript-3 {
//   vertical-align: super;
//   font-size: 0.8em;
// }

// .summary-1 {
//   grid-area: summary-1;
//   padding: 20px 20px 20px 0px;
//   min-width: 0;
// }
// .summary-2 {
//   grid-area: summary-2;
//   padding: 20px 20px 20px 0px;
//   min-width: 0;
// }

// .bar {
//   width: 30%;
//   display: inline-block;
//   margin-left: 10px;
//   min-width: 300px;
// }

// .spark-table {
//   margin: unset;
// }

// .buttons {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// }

// .flex-row {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// }

@media print {
  @page {
    margin: 1.6cm;
    background-color: white;
    padding-top: -200px;
  }

  // body {
  //   padding: 3cm;
  //   margin: 3cm;
  // }

  .pagebreak-before {
    page-break-before: always;
  } /* page-break-after works, as well */
  .pagebreak-after {
    page-break-after: always;
  }
}
</style>
