<template>
  <SparkModal custom="w-[500px] cursor-default" dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-19 font-bold" v-text="'Change Inputs'" />
    </template>

    <template #default>
      <div class="flex flex-col mx-20">
        <div class="flex flex-col text-13 p-4 z-10 pb-12">
          <SparkInput v-model.lazy="processChainName" name="name" label="Name" class="w-full" />
        </div>
        <div class="flex flex-col my-12 border rounded-8 border-gray-100">
          <div class="mb-24 p-8 border-b border-gray-50">
            <SparkButton
              small
              class="border-transparent ml-4"
              variant="outlined"
              :style="selectedTab == 'material' ? 'color: white; background-color: gray' : ''"
              @click="selectedTab = 'material'"
              >Change Material</SparkButton
            >
            <SparkButton
              small
              class="border-transparent ml-4"
              variant="outlined"
              :style="selectedTab == 'technology' ? 'color: white; background-color: gray' : ''"
              @click="selectedTab = 'technology'"
              >See Process Suitability</SparkButton
            >
            <SparkButton
              small
              class="border-transparent ml-4"
              variant="outlined"
              :style="selectedTab == 'machine' ? 'color: white; background-color: gray' : ''"
              @click="selectedTab = 'machine'"
              >View Machine</SparkButton
            >
          </div>
          <MaterialCard
            v-if="selectedTab === 'material'"
            :process-chain-uid="chain.process_chain_id"
            class="max-h-[300px]"
          />
          <MachineCard v-if="selectedTab === 'machine'" />
          <TechnologyCard v-if="selectedTab === 'technology'" />
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import SparkButton from '../../../../../SparkComponents/SparkButton.vue';
import SparkInput from '../../../../../SparkComponents/SparkInput.vue';
import SparkModal from '../../../../../SparkComponents/SparkModal.vue';

import MachineCard from './MachineCard.vue';
import MaterialCard from './MaterialCard.vue';
import TechnologyCard from './TechnologyCard.vue';

export default {
  name: 'ChangeProcessChainPopup',

  components: {
    MachineCard,
    MaterialCard,
    TechnologyCard,
    SparkButton,
    SparkInput,
    SparkModal,
  },

  props: {
    show: { type: Boolean },
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close'],

  data() {
    return {
      selectedTab: 'material',
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    ...mapState('application', ['axiosInstance']),

    processChainName: {
      get() {
        return this.chain.name;
      },

      set(value) {
        this.changeProcessName({ uid: this.chain.process_chain_id, name: value });
        this.saveProcessName();
      },
    },
  },

  methods: {
    ...mapMutations(['changeProcessName']),

    saveProcessName() {
      let uid = this.chain.process_chain_id;
      let name = this.processChainName;
      if (uid != 0) {
        this.axiosInstance.put(`/api/v1/process-chain/${uid}/`, {
          name,
        });
      }
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
