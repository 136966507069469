<template>
  <div class="max-w-[700px] mb-[300px]">
    <Teleport v-if="isMounted" to="#footer-content">
      <div class="flex justify-self-start">
        <div class="flex cursor-pointer">
          <SparkButton
            variant="outlined"
            @click="$router.push({ name: 'organization-settings', query: { scrollToPricing: 'true' } })"
          >
            <div v-text="'Price Configurations'" />
          </SparkButton>
        </div>
      </div>
      <div class="flex justify-self-center">
        <div />
      </div>
      <div class="flex justify-self-end gap-16">
        <SparkButton
          :disabled="!mode"
          :title="!mode ? 'Please select a configuration type' : ''"
          variant="secondary"
          @click="proceedButtonClicked"
        >
          <div v-text="'Next'" />
        </SparkButton>
      </div>
    </Teleport>

    <div class="text-19 font-semibold" v-text="'Configuration Type'" />
    <div class="text-13 text-gray-600" v-text="'Select the type of pricing configuration you want to create'" />
    <div
      class="flex mt-20 border border-gray-100 p-16 rounded-8 hover:cursor-pointer"
      :class="mode === 'single' ? 'bg-primary-50' : ''"
      @click="mode = 'single'"
    >
      <SparkRadio value="single" name="single" :model-value="mode" />
      <div class="flex flex-col gap-2">
        <div class="text-14 font-semibold" v-text="'Standard'" />
        <div class="text-13 text-gray-600" v-text="'Create pricing formulas based on flexible conditions.'" />
      </div>
    </div>
    <div
      class="flex border border-gray-100 p-16 mt-16 rounded-8 hover:cursor-pointer"
      :class="mode === 'combined' ? 'bg-primary-50' : ''"
      @click="mode = 'combined'"
    >
      <SparkRadio :model-value="mode" value="combined" name="combined" />
      <div>
        <div class="text-14 font-semibold" v-text="'Combined'" />
        <div
          class="text-13 text-gray-600"
          v-text="'Combine multiple standard configurations for advanced pricing models.'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkRadio from '@/components/SparkComponents/SparkRadio.vue';

export default {
  name: 'PricingOptions',

  components: { SparkButton, SparkRadio },

  data() {
    return {
      mode: '',
      isMounted: false,
    };
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    proceedButtonClicked() {
      if (this.mode === 'single') {
        this.$router.push({ name: 'pricing-single' });
      } else if (this.mode === 'combined') {
        this.$router.push({ name: 'pricing-combined' });
      }
    },
  },
};
</script>
